import $ from 'jquery'

// Když je dokument načten
document.addEventListener('DOMContentLoaded', function () {
  // Najít elementy
  const pointSelect = $('.point-select')

  // Funkce pro přepnutí tříd
  function toggleClasses() {
    const pointBox = $(this).next()
    const pointIcon = $(this).find('.point-icon')

    pointBox.toggleClass('hidden')
    pointIcon.toggleClass('rotate-45 bg-gray bg-grad')
  }

  // Přidání posluchače událostí pro kliknutí
  pointSelect.click(toggleClasses)
})
